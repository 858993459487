import React from 'react';

import HelpSection from '../../components/HelpSection/HelpSection';
import Carousel from '../../components/Carousel/Carousel';

import helpsecone from '../../assets/images/help-one.png';
import helpsecthree from '../../assets/images/help-three.png';
import helpsecfour from '../../assets/images/help-four.png';
import helpsectwo from '../../assets/images/help-two.png';

import sliderImage_one from '../../assets/images/program_deadline.jpeg';
import sliderImage_two from '../../assets/images/program_questions.jpeg';

import sliderImage_three from '../../assets/images/people_hands.jpg';
import sliderImage_four from '../../assets/images/hand_over_computer.jpg';
import sliderImage_five from '../../assets/images/water_can.jpg';

import './home.sass';

const sliderData = [
  {
    id: 1,
    image: sliderImage_three,
    header: 'Dayanışma İş Geliştirme Desteği Başladı!',
    route: '/ag-kurma'
  },
  {
    id: 2,
    image: sliderImage_four,
    header: 'Online eğitim portalımız yayında!',
    route: '/egitim'
  }
];

const Home = () => {
  const handleClick = route => {
    window.location.href = `/${route}`;
  };

  return (
    <div className="home">
      <Carousel data={sliderData} />

      <div className="help">
        <span className="help-top-header">Size nasıl yardımcı olabiliriz?</span>
        <div className="help-section">
          <HelpSection
            image={helpsecone}
            to="danismanlik"
            header="İş Geliştirme Danışmanlığı"
            text="İşletmenize özel iş geliştirme danışmanlığı hizmetimiz hakkında bilgi edinin."
          />
          <HelpSection
            image={helpsectwo}
            to="egitim"
            header="Eğitimler"
            text="Eğitim konularımıza göz atın veya online eğitim portalımıza kaydolun."
          />
          <HelpSection
            image={helpsecthree}
            to="ag-kurma"
            header="Ağ Kurma"
            text="Ağ kurma ve işbirliği faaliyetlerimiz hakkında daha fazla bilgiye erişin."
          />
          <HelpSection
            to="finansman"
            image={helpsecfour}
            header="Finansman"
            text="Hibe ve finansman olanaklarımıza göz atın."
          />
        </div>
      </div>
      <div className="entry">
        <span className="home-header">
          Kıbrıs Türk Esnaf ve Zanaatkârlar Odası, Dayanışma İş Geliştirme
          Desteği ile üyesinin yanında!
        </span>
      </div>
      <iframe
        src="https://www.youtube.com/embed/Z8mUyFrSbqc"
        title="Ecoso Dayanışma"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <div className="section">
        <span className="home-contact">Bizimle iletişime geçin!</span>
        <span className="home-contact-text">
          Hizmetlerimizle ilgili daha fazla bilgi almak için aşağıdaki butona
          tıklayıp formu doldurun.
        </span>

        <button onClick={() => handleClick('iletisim-formu')}>
          İletişim Formu
        </button>
      </div>
    </div>
  );
};

export default Home;
