import React from 'react';

const TextArea = ({ onChange, title}) => {
  const onChanged = event => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <div className="text-input">
      <span>{title}</span>
      <textarea
        cols="40"
        rows="10"
        className="textarea"
        onChange={e => onChanged(e)}
      ></textarea>
    </div>
  );
};

export default TextArea;
