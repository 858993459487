import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import Header from './components/Header/Header';
import Consultancy from './pages/Consultancy/Consultancy';
import Home from './pages/Home/Home';
import Financing from './pages/Financing/Financing';
import AboutUs from './pages/AboutUs/AboutUs';
import Footer from './components/Footer/Footer';
import Cooperation from './pages/Cooperation/Cooperation';
import ContactForm from './pages/ContactForm/ContactForm';
import Trainings from './pages/Trainings/Trainings';
import TermsOfUse from './pages/PolicyDocuments/TermsOfUse';
import CookiePolicy from './pages/PolicyDocuments/CookiePolicy';
import PrivacyPolicy from './pages/PolicyDocuments/PrivacyPolicy';
import Activities from './pages/Activities/Activities';
import ActivitiesDetail from './pages/Activities/ActivitiesDetail';
import Publication from './pages/Publication/Publication';
import GoogleForm from './pages/GoogleForm/GoogleForm';

const ScrollToView = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const Routes = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <ScrollToView>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/danismanlik">
              <Consultancy />
            </Route>
            <Route path="/finansman">
              <Financing />
            </Route>
            <Route path="/yayinlar">
              <Publication />
            </Route>
            <Route path="/hakkimizda">
              <AboutUs />
            </Route>
            <Route path="/ag-kurma">
              <Cooperation />
            </Route>
            <Route path="/iletisim-formu">
              <ContactForm />
            </Route>
            <Route path="/danismanlik-formu">
              <GoogleForm />
            </Route>
            <Route path="/egitim">
              <Trainings />
            </Route>
            <Route path="/kullanim-kosullari">
              <TermsOfUse />
            </Route>
            <Route path="/cerez-politikasi">
              <CookiePolicy />
            </Route>
            <Route path="/gizlilik-politikasi">
              <PrivacyPolicy />
            </Route>
            <Route path="/faaliyetlerimiz">
              <Activities />
            </Route>
            <Route path="/detay">
              <ActivitiesDetail />
            </Route>
          </Switch>
        </ScrollToView>
        <Footer />
      </div>
    </Router>
  );
};

export default Routes;
