import React from 'react';

import accounting from '../../assets/images/accounting.png';

import './activities.sass';

const ActivitiesDetail = () => {
  return (
    <div className="home">
      <img src={accounting} className="activities-img" />
      <span className="activities-header">
        Mikro ve küçük işletmelerin işletme muhasebesi konusunda hakim olması
        gereken temel bilgileri öğrenin ve mali tabloları kavrama ve hazırlama
        becerilerinizi geliştirin.
      </span>
      <span className="activities-content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit volutpat
        odio eu quam sagittis sit duis vitae. Euismod pellentesque ultrices nam
        volutpat mauris, cras. Habitasse rutrum purus ultrices amet, diam risus
        amet, velit pellentesque. Feugiat cras commodo facilisis quis vulputate
        suspendisse. Integer imperdiet mauris aenean sit ornare. Cursus aliquet
        semper felis sed tincidunt fringilla lorem. Quisque eu gravida posuere
        in egestas interdum in in nec. Nisl ornare ornare accumsan cursus cursus
        porttitor. Mi odio tempus felis et.
        <br /> <br /> Ut ut rutrum dignissim morbi cursus tellus posuere cras.
        Cursus lacus tortor accumsan tellus commodo, in eget. Neque, arcu, risus
        consectetur proin maecenas enim, urna nec amet. Purus fermentum quisque
        mi lacinia mauris elementum massa eu sodales. Enim ut id placerat metus
        semper dolor. Diam, amet sit at euismod varius. Eget amet, mi nunc felis
        eget sed ultrices scelerisque. Egestas dapibus odio proin dolor aliquam
        amet. Semper erat vel interdum faucibus ac et. Dictum fringilla faucibus
        non volutpat. Sed egestas faucibus metus mattis sit facilisis tellus.
        Integer volutpat in in sit sit sit phasellus egestas posuere. Mauris
        tincidunt mi consequat enim aenean. Neque, commodo malesuada diam, in
        faucibus enim. Arcu arcu, et pellentesque sociis.
      </span>
    </div>
  );
};

export default ActivitiesDetail;
