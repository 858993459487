import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from 'react-media';

import { useAuth, useOnClickOutside } from '../../hooks';

import logo from '../../assets/images/logo.png';

import './header.sass';

const Header = () => {
  const [isBurgerMenuOpen, setBurgerMenu] = useState(false);
  const location = useLocation();
  const user = useAuth();
  const mobileHeaderRef = useRef();

  useOnClickOutside(mobileHeaderRef, setBurgerMenu);

  useEffect(() => {
    setBurgerMenu(false);
  }, [location.pathname]);

  const handleClick = route => {
    window.location.href = `/${route}`;
  };
  const handleRouteClick = () => {
    window.location.href = `${process.env.REACT_APP_EDX_BASE_URL}/${
      user ? 'dashboard' : 'login'
    }`;
  };

  const data = [
    { id: 'ANASAYFA', to: '/' },
    { id: 'HAKKIMIZDA', to: '/hakkimizda' },
    { id: 'DANIŞMANLIK', to: '/danismanlik' },
    { id: 'EĞİTİM', to: '/egitim' },
    { id: 'AĞ KURMA', to: '/ag-kurma' },
    { id: 'FİNANSMAN', to: '/finansman' },
    { id: 'YAYINLAR', to: '/yayinlar' },
    { id: 'İLETİŞİM', to: '/iletisim-formu' }
  ];

  const LinkItem = () => {
    return (
      <div className="link-content">
        {data.map((item, _) => (
          <NavLink
            key={item.id}
            exact
            to={item.to}
            className="nav-item"
            activeClassName="active"
          >
            {item.id}
          </NavLink>
        ))}
      </div>
    );
  };

  return (
    <header className="header">
      <div className="header-wrapper">
        <Link className="header-logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <Media
          queries={{
            small: '(max-width: 1045px)'
          }}
        >
          {matches => (
            <Fragment>
              {!matches.small ? (
                <div className="navbar">
                  <div className="button-wrapper">
                    <button
                      className="button-auto"
                      onClick={() => handleClick('danismanlik-formu')}
                    >
                      Randevu Al
                    </button>
                    {user ? (
                      <p
                        className="username"
                        onClick={() => handleRouteClick()}
                      >
                        {user.username}
                      </p>
                    ) : (
                      <button onClick={() => handleRouteClick()}>
                        Online Eğitime Giriş
                      </button>
                    )}
                  </div>
                  <LinkItem />
                </div>
              ) : (
                <>
                  <div
                    className="burger-menu"
                    onClick={() => {
                      setBurgerMenu(!isBurgerMenuOpen);
                    }}
                  >
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                  {isBurgerMenuOpen && (
                    <div className="burger-content" ref={mobileHeaderRef}>
                      <LinkItem />
                      <Link to="/danismanlik-formu" className="nav-item">
                        RANDEVU AL
                      </Link>
                      <a
                        className="nav-item"
                        href={`${process.env.REACT_APP_EDX_BASE_URL}/${
                          user ? 'dashboard' : 'login'
                        }`}
                      >
                        {user ? user.username : ' ONLİNE EĞİTİME GİRİŞ'}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Fragment>
          )}
        </Media>
      </div>
    </header>
  );
};

export default Header;
