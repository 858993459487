import React from 'react';

import { useAuth } from '../../hooks';

import staticData from './data.json';

import HeroSection from '../../components/HeroSection/HeroSection';

import trainings from '../../assets/images/training.jpg';

import './trainings.sass';

const CourseList = ({ data }) => {
  return Object.entries(data).map(([category, categoryData]) => {
    return <CourseItem key={category} data={categoryData} />;
  });
};

const CourseItem = ({ data }) => {
  if (data.length < 1) {
    return;
  }

  return data.map((item, index) => {
    const courseRun = item.course_run;
    if (!courseRun) return null;
    const description = courseRun.short_description;
    return (
      <div
        className="trainings-box"
        key={index}
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_EDX_BASE_URL}/courses/${courseRun.key}/about`)
        }
      >
        <div className="trainings-box-left">
          <img src={courseRun.image.src} alt={courseRun.title} />
        </div>
        <div className="trainings-box-right">
          <span className="trainings-box-title">{courseRun.title}</span>
          <ul className="trainings-content-text description-text">
            {description}
          </ul>
        </div>
      </div>
    );
  });
};

const Trainings = () => {
  // const { data, status, error } = useCourses();
  const user = useAuth();

  // if (status === 'loading') return <p>Loading</p>;
  // else if (status === 'error') return <p>Error Occured : {error.message}</p>;

  return (
    <div>
      <HeroSection text={`Online \nEğitim Portalı`} src={trainings} />

      <div className="trainings">
        <span className="trainings-second-title">
          Fark yaratıcı eğitimlerle, kendi hızınızda öğrenme fırsatı!
        </span>
        <span className="trainings-content-text description-text">
          Yenilikçi bir yaklaşımla hazırlanan online eğitim portalımız hem
          katılım saatleri ve eğitim programının tamamlanmasında esneklik, hem
          de eğitmenlerle ve danışmanlara online istişare imkânı sağlar.
          Katılımcılar eğitimlere istedikleri zaman erişebilirler, ve kendi
          programlarına uygun bir şekilde takip edebilirler ve programı
          istedikleri sürede tamamlayabilirler.
          <br />
          <br />
          Mikro ve küçük işletmelerin daha rekabet edebilir hale gelerek yerel
          ekonomik kalkınmaya öncülük etmelerine destek olmak için ilk etapta 8
          önemli eğitim konusu belirlenmiştir.
        </span>

        <button
          onClick={() =>
            (window.location.href = user
              ? `${process.env.REACT_APP_EDX_BASE_URL}/dashboard`
              : `${process.env.REACT_APP_EDX_BASE_URL}/register`)
          }
        >
          Hemen Kaydol
        </button>

        <CourseList data={staticData} />
      </div>
    </div>
  );
};

export default Trainings;
