import React from 'react';

import './publication.sass';

import HeroSection from '../../components/HeroSection/HeroSection';

import guideline1 from '../../assets/documents/01_Food_Safety_Guidelines.pdf';
import guideline2 from '../../assets/documents/02_Digital_Photography Guidelines.pdf';
import guideline3 from '../../assets/documents/03_Marketing_Guidelines.pdf';
import guideline4 from '../../assets/documents/04_Finance_and_Accounting_Guidelines.pdf';
import guideline5 from '../../assets/documents/05_Service_and_Customer_Standards_Guidelines.pdf';
import guideline6 from '../../assets/documents/06_Sales_Guidelines.pdf';
import guideline7 from '../../assets/documents/07_Business_Development_Innovation_Guidelines.pdf';

import guidelineImg1 from '../../assets/images/guidelines/guidelines-1.jpeg';
import guidelineImg2 from '../../assets/images/guidelines/guidelines-2.png';
import guidelineImg3 from '../../assets/images/guidelines/guidelines-3.jpeg';
import guidelineImg4 from '../../assets/images/guidelines/guidelines-4.jpeg';
import guidelineImg5 from '../../assets/images/guidelines/guidelines-5.jpeg';
import guidelineImg6 from '../../assets/images/guidelines/guidelines-6.jpeg';
import guidelineImg7 from '../../assets/images/guidelines/guidelines-7.jpeg';

import publicationBanner from '../../assets/images/publication.jpeg';

import HelpSection from '../../components/HelpSection/HelpSection';

const Publication = () => {
  return (
    <div>
      <HeroSection text="Yayınlar" src={publicationBanner} />

      <div className="publication">
        <div className="help">
          <span className="help-top-header">
            Dayanışma İş Geliştirme Desteği yayınlarına göz atın!
          </span>
          <div className="help-section">
            <HelpSection
              image={guidelineImg1}
              to={guideline1}
              text="Tarladan sofraya gıda güvenliğini sağlamanın yolu nedir?"
              target={'_blank'}
            />

            <HelpSection
              image={guidelineImg2}
              to={guideline2}
              text="Sosyal medya için göz alıcı görselleri nasıl oluştururum?"
              target={'_blank'}
            />

            <HelpSection
              image={guidelineImg3}
              to={guideline3}
              text="Pazarlama yöntemlerimi nasıl geliştirir ve çeşitlendiririm?"
              target={'_blank'}
            />

            <HelpSection
              image={guidelineImg4}
              to={guideline4}
              text="İşletmelerde finansal planlamanın altın kuralı nedir?"
              target={'_blank'}
            />

            <HelpSection
              image={guidelineImg5}
              to={guideline5}
              text="Müşteri memnuniyetini nasıl artırırım?"
              target={'_blank'}
            />

            <HelpSection
              image={guidelineImg6}
              to={guideline6}
              text="Daha çok kazandıracak satış tekniklerini nasıl uygularım?"
              target={'_blank'}
            />

            <HelpSection
              image={guidelineImg7}
              to={guideline7}
              text="Ürün ve hizmetlerimi inovatif fikirlerle nasıl geliştiririm?"
              target={'_blank'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication;
