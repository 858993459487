import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import edxClient from '../api/edxClient';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const { data } = useQuery('', async () => {
    const { data } = await edxClient.get('/api/user/v1/me', {
      withCredentials: true
    });

    return data;
  });

  useEffect(() => {
    if (data) {
      const username = data.username;
      setUser({ username });
    }
  }, [data]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
