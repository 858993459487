import React from 'react';

import HeroSection from '../../components/HeroSection/HeroSection';

import termsOfUse from '../../assets/images/terms-of-use.jpg';

import './policy-documents.sass';

const TermsOfUse = () => {
  return (
    <>
      <HeroSection src={termsOfUse} text="Kullanım Koşulları" />
      <div className="terms-of-use wrapper">
        <span className="terms-of-use-content-text">
          Bu sayfa (ve içerisinde atıfta bulunulan politikalar), websitemizi
          kullanırken uymanız gereken kuralları anlatır. Platformu kullanmadan
          önce bu kuralları okumanızı tavsiye ederiz. Sitemizi kullanarak,
          kullanım koşullarımız kabul ettiğinizi ve bu koşullara uyacağınızı
          onaylamaktasınız. Kullanım koşullarını kabul etmemeniz durumunda
          sitemizi kullanmamalısınız.
        </span>

        <p className="terms-of-use-header">Platform Kuralları ve Koşulları</p>
        <div className="terms-of-use-content">
          <p className="terms-of-use-subtitle">
            1- BU KOŞULLARA DEĞİŞİKLİK YAPABİLİRİZ
          </p>
          <span className="terms-of-use-content-text">
            Bu kullanım koşullarını zaman zaman revize edebiliriz. Sitemizi
            kullanmak istediğinizde, bu koşulları kontrol ederek, ilgili
            kuralların ne olduğunu anladığınızdan emin olunuz.
          </span>

          <p className="terms-of-use-subtitle">2- HAKKIMIZDA</p>
          <span className="terms-of-use-content-text">
            Bu platform (www.ktezodayanisma.org,
            https://lms.ktezodayanisma.org), Kıbrıs’ta, KTEZO Sanayi Sitesi, Dr
            Fazıl Küçük Bulvarı, Hamitköy, Lefkoşa adresinde kayıtlı olan Kıbrıs
            Türk Esnaf ve Zanaatkârlar Odası (kayıt no: YK217; bundan sonra
            ‘KTEZO’ veya ‘Oda’ olarak anılacaktır) ve Ali Güçlü Sokak, Metehan,
            Lefkoşa adresinde kayıtlı olan Centre for Economic and Social
            Policies (kayıt no: M.Ş. 19759, bundan sonra ECOSO olarak
            anılacaktır) tarafından işletilmektedir.
          </span>

          <p className="terms-of-use-subtitle">
            3- SİZİN İÇİN GEÇERLİ OLABİLECEK DİĞER ŞARTLAR
          </p>
          <span className="terms-of-use-content-text">
            Aşağıdaki politikalar da sitemizi kullanırken geçerlidir:
            <ul>
              <li>
                Gizlilik Politikamız – sizden topladığımız veya bize
                sağladığınız herhangi bir kişisel veriyi işlememize ilişkin
                şartları tanımlar. Sitemizi kullanarak, bu tür işlemlere izin
                vermiş ve sizin tarafınızdan sağlanan tüm verilerin doğru
                olduğunu garanti etmiş olursunuz.
              </li>
              <li>
                Çerez Politikamız – sitemizde kullanılan çerezler hakkında bilgi
                sunar.
              </li>
            </ul>
          </span>

          <p className="terms-of-use-subtitle">
            4- SİTEMİZE DEĞİŞİKLİKLER YAPABİLİRİZ
          </p>
          <span className="terms-of-use-content-text">
            Hizmetlerimize, üyelerimizin ihtiyaçlarına veya önceliklerimizdeki
            değişiklikleri yansıtmak amacıyla zaman zaman sitemizde
            değişiklikler veya güncellemeler yapabiliriz. Büyük ölçekli bir
            değişiklik olması öncesinde size makul bir süre içerisinde
            bildirimde bulunmaya çalışacağız.
          </span>

          <p className="terms-of-use-subtitle">
            5- SİTEMİZİ ASKIYA ALABİLİR VEYA KALDIRABİLİRİZ
          </p>
          <span className="terms-of-use-content-text">
            Sitemizin veya üzerindeki herhangi bir içeriğin hatasız veya eksik
            olmayacağını veya her zaman kullanılabilir olacağını veya kesintisiz
            olacağını garanti etmiyoruz. Sitemizin tamamının veya herhangi bir
            kısmının kullanılabilirliğini ticari veya operasyonel nedenlerle
            askıya alabilir veya geri çekebilir veya kısıtlayabiliriz. Herhangi
            bir askıya alma veya geri çekme durumunda size makul bir süre
            önceden bildirimde bulunmaya çalışacağız.
            <br />
            <br />
            Sitemize erişiminiz için gerekli tüm düzenlemeleri yapmaktan
            sorumlusunuz. İnternet bağlantınız aracılığıyla sitemize erişen tüm
            kişilerin bu şartlardan haberdar olmalarını ve bunlara uymalarını
            sağlamaktan da sorumlusunuz.
          </span>

          <p className="terms-of-use-subtitle">
            6- SİTEMİZDEKİ İÇERİKLERİN KULLANIMI
          </p>
          <span className="terms-of-use-content-text">
            Sitemizdeki ve üzerinde yayınlanan materyaldeki tüm fikri mülkiyet
            haklarının sahibi veya lisans sahibiyiz. Bu eserler dünya çapında
            telif hakkı yasaları ve anlaşmalarıyla korunmaktadır. Tüm bu haklar
            saklıdır.
            <br />
            <br />
            Kişisel referansınız için sitemizden herhangi bir
            sayfanın/sayfaların bir kopyasını yazdırabilir ve özetlerini
            indirebilirsiniz ve sitemizde yayınlanan materyallere kurumunuzdaki
            diğer kişilerin dikkatini çekmek için paylaşabilirsiniz.
            <br /> <br />
            Yazdırdığınız veya indirdiğiniz herhangi bir materyalin kağıt veya
            dijital kopyalarını herhangi bir şekilde değiştirmemelisiniz ve
            herhangi bir resim, fotoğraf, video veya ses dizisini veya herhangi
            bir grafiği beraberindeki metinden ayrı olarak kullanmamalısınız.{' '}
            <br /> <br />
            Sitemizdeki materyalin yazarları olarak durumumuz (ve katkıda
            bulunanların durumu) her zaman tanınmalıdır. <br /> <br />
            Sitemizdeki materyallerin hiçbir bölümünü, bizden bir lisans almadan
            ticari amaçlarla kullanmamalısınız. <br /> <br />
            Sitemizin herhangi bir bölümünü bu kullanım şartlarını ihlal ederek
            yazdırır, kopyalar veya indirirseniz, sitemizi kullanma hakkınız
            derhal sona erecektir ve bizim seçimimize bağlı olarak, yaptığınız
            materyallerin kopyalarını iade etmeniz veya imha etmeniz gerekir.{' '}
            <br /> <br />
            Bu site veya içeriğinin hiçbir bölümü önceden tarafımızdan yazılı
            izin alınmadan herhangi bir biçimde veya ortamda çoğaltılamaz veya
            saklanamaz.
          </span>

          <p className="terms-of-use-subtitle">
            7- SİTEDEKİ BİLGİLER VE İÇERİKLER TAVSİYE NİTELİKLİDİR
          </p>
          <span className="terms-of-use-content-text">
            Sitemizdeki içerik genel bilgi ve tavsiye amaçlıdır. Sitemizde
            yayınlanan yorumlar ve diğer materyaller, hukuki veya mali bir
            danışmanlık olarak kabul edilemez. <br /> <b />
            Sitemizdeki bilgileri güncellemek için makul çabayı göstermemize
            rağmen, sitemizdeki içeriğin doğru, eksiksiz veya güncel olduğuna
            dair açık veya zımni hiçbir beyan veya garanti vermeyiz.
          </span>

          <p className="terms-of-use-subtitle">
            8- HESAP BİLGİLERİNİZİ GÜVENLİ BİR ŞEKİLDE SAKLAMANIZ GEREKİR
          </p>
          <span className="terms-of-use-content-text">
            Güvenlik prosedürlerimizin bir parçası olarak bir kullanıcı
            tanımlama kodu, şifre veya başka herhangi bir bilgi size verilmişse
            veya seçmişseniz, bu bilgileri gizli tutmalısınız. Herhangi bir
            üçüncü şahıs ile paylaşmamalısınız.
            <br />
            <br />
            Makul görüşümüze göre bu kullanım şartlarının herhangi bir hükmüne
            uymadıysanız, herhangi bir zamanda, sizin tarafınızdan seçilmiş veya
            bizim tarafımızdan tahsis edilmiş herhangi bir kullanıcı tanımlama
            kodunu veya şifresini devre dışı bırakma hakkına sahibiz.
            <br /> <br />
            Sizden başka birinin kullanıcı kimlik kodunuzu veya şifrenizi
            bildiğini biliyor veya bundan şüpheleniyorsanız, bize{' '}
            <span> info@ktezodayanisma.org </span>
            adresinden derhal haber vermelisiniz.
          </span>

          <p className="terms-of-use-subtitle">
            9- VİRÜSLERDEN SORUMLU DEĞİLİZ VE VİRÜS BULAŞTIRMAMALISINIZ
          </p>
          <span className="terms-of-use-content-text">
            Sitemizi kötü amaçlı veya teknolojik olarak zararlı virüsler,
            trojanlar, yazılım bombaları veya diğer materyalleri kasti olarak
            bulaştırarak kötüye kullanmamalısınız. Sitemize, sitemizin
            depolandığı sunucuya veya sitemize bağlı herhangi bir sunucuya,
            bilgisayara veya veritabanına yetkisiz erişim sağlamaya
            çalışmamalısınız. Sitemize bir hizmeti engelleme saldırısı veya
            dağıtılmış hizmeti engelleme saldırısı yoluyla saldırmamalısınız.{' '}
            <br /> <br />
            Bu tür ihlaller ilgili otoritelere bildirilip kimliğiniz
            paylaşılacaktır. Böyle bir ihlal durumunda sitemizi kullanma
            hakkınız derhal sona erecektir. <br /> <br /> Gelişmiş güvenlik
            önlemleri alsak da sitemizin güvenli olacağını veya hatalardan veya
            virüslerden arınmış olacağını garanti etmiyoruz. <br /> <br />{' '}
            Sitemize erişmek için bilgi teknolojinizi, programlarınızı ve
            platformunuzu yapılandırmaktan kendiniz sorumlusunuz. Kendi virüs
            koruma yazılımınızı kullanmalısınız. Sitemizi kullanmanız veya web
            sitesinde veya bağlantılı bir websitesinde yayınlanan herhangi bir
            materyali indirmeniz esnasında bilgisayarınıza/akıllı cihazınıza,
            bilgisayar/akıllı cihaz programlarınıza, verilerinize veya diğer
            özel materyallere bulaşabilecek dağıtılmış bir hizmeti engelleme
            saldırısı, virüsler veya diğer teknolojik olarak zararlı
            materyallerin neden olduğu herhangi bir kayıp veya hasar için
            sorumluluk kabul etmiyoruz.
          </span>

          <p className="terms-of-use-subtitle">
            10- BAĞLANTI VERDİĞİMİZ WEB SİTELERİNDEN SORUMLU DEĞİLİZ
          </p>
          <span className="terms-of-use-content-text">
            Sitemizin üçüncü şahıslar tarafından sağlanan diğer sitelere ve
            kaynaklara bağlantılar içerdiği durumlarda, bu bağlantılar yalnızca
            bilginiz için sağlanır. Bu tür bağlantılar, bağlantılı web
            sitelerinin veya onlardan edinebileceğiniz bilgilerin tarafımızca
            onaylandığı şeklinde yorumlanmamalıdır. Bu sitelerin veya
            kaynakların içeriği üzerinde hiçbir kontrolümüz yoktur ve bunlar
            için veya bunları kullanmanızdan kaynaklanabilecek herhangi bir
            kayıp veya hasar için hiçbir sorumluluk kabul etmiyoruz.
          </span>

          <p className="terms-of-use-subtitle">
            11- KULLANICI TARAFINDAN OLUŞTURULAN İÇERİKLER TARAFIMIZDAN
            ONAYLANMIŞ OLARAK KABUL EDİLMEMELİDİR
          </p>
          <span className="terms-of-use-content-text">
            Bu web sitesi, bülten panoları vb. dahil olmak üzere sitenin diğer
            kullanıcıları tarafından yüklenen bilgileri ve materyalleri
            içerebilir. Bu bilgiler ve bu içerikler tarafımızca doğrulanmamış
            veya onaylanmamıştır. Sitemizdeki diğer kullanıcılar tarafından
            ifade edilen görüşler bizim görüşlerimizi veya değerlerimizi temsil
            etmemektedir.
            <br />
            <br />
            Diğer kullanıcılar tarafından yüklenen bilgi ve materyaller
            konusunda bir şikayetiniz var ise,{' '}
            <span>info@ktezodayanisma.org </span> emailinden bize
            ulaşabilirsiniz.
          </span>

          <p className="terms-of-use-subtitle">
            12- SİTEMİZE BAĞLANTI VERİLMESİNE İLİŞKİN KURALLAR
          </p>
          <span className="terms-of-use-content-text">
            Sitemize, adil ve yasal bir şekilde ve itibarımıza zarar vermeyecek
            veya bundan yararlanmayacak şekilde bağlantı verebilirsiniz, ancak
            herhangi bir ilişki biçimi, tarafımızdan onay veya destek önerecek
            şekilde bağlantı vermemelisiniz. <br /> <br /> Size ait olmayan
            herhangi bir web sitesinden bağlantı vermemelisiniz. <br /> <br />{' '}
            Sitemiz başka herhangi bir sitede çerçevelenmemelidir ve sitemizin
            ana sayfası dışında herhangi bir bölümüne bağlantı
            oluşturmamalısınız. <br /> <br /> Bilgi vermeden bağlantı iznini
            geri çekme hakkımız saklıdır. <br /> <br /> Bağlandığınız web
            sitesi, aşağıda "Sitemizin Kabul Edilebilir Kullanımı" başlığı
            altında belirtilen içerik standartlarına her açıdan uygun olmalıdır.{' '}
            <br /> <br /> Yukarıda belirtilenler dışında sitemizdeki herhangi
            bir materyali kullanmak isterseniz, lütfen talebinizi{' '}
            <span>info@ktezodayanisma.org </span> adresine gönderin.
          </span>

          <p className="terms-of-use-subtitle">
            13- SİTEMİZİN KABUL EDİLEBİLİR KULLANIMI
          </p>
          <span className="terms-of-use-content-text">
            Aşağıdaki koşullar sitemizin tüm kullanıcıları ve ziyaretçileri için
            geçerlidir. Sitemizi kullanmanız, buradaki tüm hükümleri kabul
            ettiğiniz ve bunlara uymayı kabul ettiğiniz anlamına gelir. <br />{' '}
            <br />
            Sitemizi yalnızca yasal amaçlarla kullanabilirsiniz. Sitemizi
            aşağıdaki amaç veya şekillerde kullanamazsınız:
            <br /> <br />
            <ul>
              <li>
                herhangi bir şekilde geçerli yerel yasal metinler, veya
                uluslararası yasa veya düzenlemeleri ihlal eden şekilde;
              </li>
              <li>
                herhangi bir şekilde yasa dışı veya hileli olan veya herhangi
                bir yasadışı veya hileli amacı veya etkisi olan şekilde;
              </li>
              <li>
                herhangi bir şekilde küçüklere zarar vermek veya vermeye
                teşebbüs etmek amacıyla
              </li>
              <li>
                İçerik Standartlarımıza uymayan herhangi bir materyali
                göndermek, bilerek almak, yüklemek, indirmek, kullanmak veya
                yeniden kullanmak
              </li>
              <li>
                herhangi bir istenmeyen veya yetkisiz reklam veya promosyon
                materyalini veya diğer benzer talepleri (spam) iletmek veya
                gönderilmesini sağlamak; veya
              </li>
              <li>
                herhangi bir virüs, Trojan, yazılım bombası, tuş vuruşu
                kaydedici, casus yazılım, reklam yazılımı veya diğer zararlı
                programlar veya herhangi bir bilgisayar yazılımının veya
                ekipmanının çalışmasını olumsuz şekilde etkilemek için
                tasarlanmış benzer bilgisayar kodu içeren herhangi bir materyali
                veya veriyi göndermek veya yüklemek; veya
              </li>
              <li>
                bu web sitesi kullanım şartlarının hükümlerine aykırı olarak
                sitemizin herhangi bir bölümünü çoğaltmak, kopyalamak veya
                yeniden satmak.
              </li>
            </ul>
            Ayrıca, sitenin herhangi bir bölümünü, sitenin depolandığı herhangi
            bir ekipman veya ağı, ve sitemizin sağlanmasında kullanılan herhangi
            bir yazılım, veya herhangi bir üçüncü tarafa ait veya tarafından
            kullanılan herhangi bir ekipman, ağ veya yazılıma yetkisiz
            erişmemeyi, müdahale etmemeyi, zarar vermemeyi veya bozmamayı da
            kabul edersiniz.
          </span>

          <span className="terms-of-use-subheader">İnteraktif Hizmetler</span>
          <span className="terms-of-use-content-text">
            Zaman zaman sitemizde forumlar, online chat dahil interaktif
            hizmetler sunabiliriz.
            <br /> <br /> Herhangi bir interaktif hizmetin sunulması durumunda,
            sunulan hizmetin türü, (var ise) moderasyonu ve ne tür moderasyon
            kullanıldığı (insan veya teknik olup olmadığı dahil) hakkında size
            net bilgiler sağlayacağız. <br /> <br />
            Sitemizde sağlanan herhangi bir interaktif hizmeti kullandıklarında
            kullanıcılar (ve özellikle yaşı küçük kullanıcılar için) için olası
            riskleri değerlendirmek için çalışacağız. Her durumda, bu riskler
            ışığında ilgili hizmetin moderasyonunun gerekli olup olmadığı ayrı
            değerlendirilecektir. Fakat, sitemizde sunduğumuz herhangi bir
            interaktif hizmeti denetleme, izleme veya moderasyonunu yapma
            yükümlülüğümüz bulunmamaktadır ve içerik standartlarımıza aykırı
            olarak bir kullanıcı tarafından herhangi bir interaktif hizmetin
            kullanımından kaynaklanan herhangi bir kayıp veya hasar için
            sorumluluğumuzu hizmetin denetlenip denetlenmemesi farketmeksizin
            açıkça hariç tutarız. <br /> <br />
            İnteraktif hizmetlerimizden herhangi birinin reşit olmayan bir kişi
            tarafından kullanılması, ebeveynlerinin veya velilerinin onayına
            tabidir. Çocuklarının interaktif bir hizmeti kullanmalarına izin
            veren ebeveynlere, moderasyon kusursuz olmadığından, çocuklarıyla
            çevrimiçi güvenlikleri hakkında iletişim kurmalarının önemli
            olduğunu tavsiye ederiz. Herhangi bir interaktif hizmeti kullanan
            reşit olmayan kişiler, kendileri için potansiyel riskler konusunda
            bilgilendirilmelidir. <br /> <br />
            İnteraktif bir hizmetin moderasyonunun yapıldığı durumlarda, bir
            endişe veya zorluk ortaya çıkması durumunda moderatörle iletişim
            kurmanın bir yolu sunulur.
          </span>

          <span className="terms-of-use-subheader">İçerik Standartları</span>
          <span className="terms-of-use-content-text">
            Bu içerik standartları, sitemize katkıda bulunduğunuz tüm
            materyaller ("katkılar") ve onunla ilişkili tüm interaktif hizmetler
            için geçerlidir. <br /> <br />
            Aşağıdaki standartların tümüne uymalısınız. Standartlar, herhangi
            bir katkının her bir parçası ve tamamı için geçerlidir.
            <br /> <br /> Katkılar (gerçekleri ifade ettikleri durumlarda) doğru
            olmalı, (görüş bildirdikleri durumlarda) gerçekten tutulabilir
            olmalı ve Kıbrıs’ın kuzeyinde ve gönderildikleri herhangi bir ülkede
            geçerli yasal metinlere uymalıdır.
            <br /> <br />
          </span>
          <span className="terms-of-use-content-text">Katkılar:</span>
          <br />
          <ul>
            <li>
              Herhangi bir kişiyi karalayan herhangi bir materyal içermemelidir;
            </li>
            <li>
              Müstehcen, saldırgan, nefret dolu veya kışkırtıcı herhangi bir
              materyal içermemelidir;
            </li>
            <li>Müstehcen materyalleri teşvik etmemelidir;</li>
            <li>şiddeti teşvik etmemelidir;</li>
            <li>
              Irk, cinsiyet, din, milliyet, engellilik, cinsel yönelim veya yaşa
              dayalı ayrımcılığı teşvik etmemelidir;
            </li>
            <li>
              Herhangi bir başka kişinin herhangi bir telif hakkını, veritabanı
              hakkını veya ticari markasını ihlal etmemelidir;
            </li>
            <li> Herhangi bir kişiyi aldatma olasılığı bulunmamalıdır;</li>
            <li>
              Üçüncü bir şahsa borçlu olunan herhangi bir yasal yükümlülüğün,
              örneğin bir sözleşmeden doğan görev veya güven yükümlülüğünü ihlal
              edecek şekilde yapılmamalıdır;
            </li>
            <li>Herhangi bir yasa dışı faaliyeti teşvik etmemelidir;</li>
            <li>
              Tehdit edici, kötüye kullanma veya başkasının mahremiyetini ihlal
              etme veya kızgınlığa, rahatsızlığa veya gereksiz endişeye neden
              yaratmamalıdır;
            </li>
            <li>Herhangi bir kişiyi taciz etme, üzme,</li>
            <li>
              Utandırma, alarm verme veya rahatsız etme olasılığı olmamalıdır;{' '}
            </li>
            <li>
              Herhangi bir kişiyi taklit etmek veya kimliğinizi veya herhangi
              bir kişiyle olan ilişkinizi yanlış tanıtmak için
              kullanılmamalıdır;
            </li>
            <li>Katkının KTEZO’dan veya ECOSO’dan geldiği izlenimini vermemelidir;</li>
            <li>
              Herhangi bir tarafın telif hakkı ihlali veya bilgisayarın kötüye
              kullanımı gibi (yalnızca örnek olarak) herhangi bir yasadışı veya
              cezai eylemde bulunmasını veya yardımcı olmasını savunmamalıdır,
              teşvik etmemelidir;
            </li>
            <li>
              {' '}
              Herhangi bir hizmeti tanıtıcı reklam veya web linkleri
              içermemelidir.
            </li>
          </ul>

          <span className="terms-of-use-subheader">Askıya Alma ve Fesih</span>
          <span className="terms-of-use-content-text">
            Sitemizi kullanmanız durumunda bu "Kabul Edilebilir Kullanım"
            maddesinin veya içerik standartlarının ihlal edilip edilmediğini
            kendi takdirimize bağlı olarak belirleyeceğiz. Aynı ihlal meydana
            geldiğinde, uygun gördüğümüz şekilde önlem alabiliriz.
            <br />
            <br /> Bu "Kabul Edilebilir Kullanım" maddesine veya içerik
            standartlarına uyulmaması, sitemizi kullanmanıza izin verilen
            koşulların önemli bir ihlalini teşkil eder ve aşağıdaki eylemlerin
            tümünü veya herhangi birini almamıza neden olabilir: <br /> <br />
            <ul>
              <li>
                Sitemizi kullanma hakkınızın derhal, geçici veya kalıcı olarak
                geri çekilmesi;
              </li>
              <li>
                Tarafınızdan sitemize yüklenen herhangi bir katkı, gönderi veya
                materyalin derhal, geçici veya kalıcı olarak kaldırılması;
              </li>
              <li>Size bir uyarı verilmesi;</li>
              <li>
                İhlalden kaynaklanan tüm masrafların tazminat temelinde (makul
                idari ve yasal masraflar dahil ancak bunlarla sınırlı olmamak
                üzere) geri ödenmesi için aleyhinize yasal kovuşturma;
              </li>
              <li> Size karşı daha fazla yasal işlem yapılması; ve / veya </li>
              <li>
                Makul bir şekilde gerekli olduğunu düşündüğümüzde, bu tür
                bilgilerin emniyet yetkilileri ile paylaşılması
              </li>
            </ul>
            <br /> <br />
            Bu "Kabul Edilebilir Kullanım" hükmünün ihlaline yanıt olarak
            yapabileceğimiz tüm eylemler için sorumluluk kabul etmemekteyiz.
            Yapabileceğimiz eylemler yukarıda açıklananlarla sınırlı değildir ve
            makul olarak uygun gördüğümüz başka herhangi bir eylemde
            bulunabiliriz.
          </span>

          <p className="terms-of-use-subtitle">14- SİTEMİZE İÇERİK YÜKLEMEK</p>
          <span className="terms-of-use-content-text">
            Sitemize içerik yüklemenize veya sitemizin diğer kullanıcılarıyla
            iletişim kurmanıza izin veren bir özelliği kullandığınızda,
            yukarıdaki "Kabul Edilebilir Kullanım" maddesinde belirtilen içerik
            standartlarına uymanız gerekir. <br />
            <br />
            Bu tür bir katkının bu standartlara uygun olduğunu, bize karşı
            sorumlu olacağınızı ve bu garantinin ihlali durumunda bizi tazmin
            edeceğinizi kabul edersiniz. Bu, garantinizi ihlal etmenizin bir
            sonucu olarak maruz kaldığımız her türlü kayıp veya hasardan sorumlu
            olacağınız anlamına gelir.
            <br />
            <br /> Sitemize yüklediğiniz herhangi bir içerik gizli ve tescilli
            olmayan içerik olarak kabul edilecektir. İçeriğinizdeki tüm mülkiyet
            haklarınızı saklı tutarsınız, ancak bize ve sitemizin diğer
            kullanıcılarına bu içeriği kullanmak, saklamak ve kopyalamak ve
            üçüncü şahıslara dağıtmak ve sunmak için sınırlı bir lisans
            verdiğiniz kabul edilir.
            <br />
            <br /> Sizin tarafınızdan sitemize gönderilen veya yüklenen herhangi
            bir içeriğin fikri mülkiyet haklarını veya gizlilik haklarını ihlal
            ettiğini iddia eden herhangi bir üçüncü tarafla kimliğinizi paylaşma
            hakkına sahibiz.
            <br />
            <br /> Yayınınız yukarıdaki "Kabul Edilebilir Kullanım" maddesinde
            belirtilen içerik standartlarına uymadığı kanatinde olmamız
            durumunda, sitemizde yaptığınız herhangi bir gönderiyi kaldırma
            hakkına sahibiz. İçeriğinizin güvenliğini sağlamak ve yedeklemekten
            yalnızca siz sorumlusunuz.
          </span>

          <p className="terms-of-use-subtitle">
            15- KAYIPLARINIZ VEYA ZARARLARINIZA YÖNELİK SORUMLULUKLARIMIZ
          </p>
          <span className="terms-of-use-content-text">
            Yasaların izin verdiği ölçüde, KTEZO ve bağlı üçüncü şahıslar,
            sitemize veya içeriğine uygulanabilecek tüm zımni koşulları,
            garantileri, beyanları ve diğer şartları açıkça hariç tutarız.{' '}
            <br />
            <br />
            Sözleşme, haksız fiil (ihmal dahil), yasal görevin ihlali veya başka
            bir şekilde, aşağıdakiler altında veya bunlarla bağlantılı olarak
            öngörülebilir olsa bile, herhangi bir kayıp veya hasardan sorumlu
            olmayacağız: <br /> <br />
            <ul>
              <li>Sitemizin kullanımı veya kullanılamaması; veya</li>
              <li>Sitemizin kullanımının sonuçları;</li>
              <li>
                Bağlantılı herhangi bir web sitesinin veya sitemizde
                görüntülenen herhangi bir içeriğin kullanılması veya bunlara
                güvenilmesi.
              </li>
            </ul>
            Özellikle aşağıdakilerden sorumlu olmayacağız:
            <br /> <br />
            <ul>
              <li>Gelir, kâr, satış, sözleşme, iş veya gelir kaybı;</li>
              <li>İş kesintisi;</li>
              <li>Beklenen tasarruf kaybı;</li>
              <li>İş fırsatı, itibar veya itibar kaybı;</li>
              <li>Veri kaybı;</li>
              <li>Boşa giden yönetsel veya ofis zamanı; veya</li>
              <li>
                Herhangi bir dolaylı veya direkt olarak ortaya çıkan kayıp veya
                hasar
              </li>
            </ul>
          </span>

          <p className="terms-of-use-subtitle">16- SORULARINIZ</p>
          <span className="terms-of-use-content-text">
            Sitemizde bulunan materyaller konusunda sorularınız olması
            durumunda, bizimle <span>info@ktezodayanisma.org </span> adresinden
            irtibata geçebilirsiniz. <br /> <br />
            Sitemizi ziyaret ettiğiniz için teşekkür ederiz.
          </span>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
