import React from 'react';
import { SELECT_OBJECT } from '../../constants';

import './checked.sass';

const ItemList = ({ checkedElement, toggleCheckbox, header }) => {
  return (
    <>
      <span className="check-header">{header}</span>
      <div className="container">
        <div>
          <input
            type="checkbox"
            onChange={toggleCheckbox}
            id={SELECT_OBJECT.FIRST}
            checked={checkedElement === SELECT_OBJECT.FIRST}
          />
          <span>Evet</span>
        </div>
        <div>
          <input
            type="checkbox"
            onChange={toggleCheckbox}
            id={SELECT_OBJECT.SECOND}
            checked={checkedElement === SELECT_OBJECT.SECOND}
          />
          <span>Hayır</span>
        </div>
      </div>
    </>
  );
};

const Checked = ({ header, checkedElement, setCheckedElement }) => {
  const toggleCheckbox = e => {
    if (checkedElement !== e.target.id) {
      setCheckedElement(e.target.id);
    }
  };

  return (
    <ItemList
      checkedElement={checkedElement}
      toggleCheckbox={e => toggleCheckbox(e)}
      header={header}
    />
  );
};

export default Checked;
