import React from 'react';

import './heroSection.sass';

const HeroSection = ({ text, src }) => {
  return (
    <div className="hero-wrapper">
      <img src={src} className="hero-wrapper-image" alt="hero" />
      <span className="hero-wrapper-text">{text}</span>
    </div>
  );
};

export default HeroSection;
