import React from 'react';

import HeroSection from '../../components/HeroSection/HeroSection';

import cookies from '../../assets/images/cookie.jpg';

import './policy-documents.sass';

const CookiePolicy = () => {
  return (
    <>
      <HeroSection src={cookies} text="Çerez Politikası" />
      <div className="cookies wrapper">
        <span className="cookies-content-text">
          Sizi sitenin diğer kullanıcılarından ayırmak için çerez dediğimiz
          tanımlama bilgilerini kullanmaktayız. Bu web sitemizi kullanırken size
          daha iyi bir deneyim sunmamıza ve ayrıca sitemizi geliştirmemize
          yardımcı olmaktadır.
        </span>
        <p>Çerezler nedir?</p>
        <span className="cookies-content-text">
          Çerez, kabul etmeniz durumunda tarayıcınızda veya bilgisayarınızın
          sabit sürücüsünde sakladığımız küçük bir harf ve sayı dosyasıdır.
          Çerezler, bilgisayarınızın sabit diskine aktarılan bilgileri içerir.
        </span>
        <span className="cookies-content-text list-header">
          Aşağıda belirtilen çerezleri kullanmaktayız:
        </span>
        <ul>
          <li>
            Kullanılması zorunlu olan çerezler: Websitesinin işletimi için elzem
            olan çerezlerdir. Bunlar örneğin, websitesinin güvenli alanlarına
            oturum açmanıza olanak sağlayan tanımlama bilgilerini içerir.
          </li>
          <li>
            Analitik/performans çerezleri: Siteye gelen ziyaretçileri
            tanımamızı, ziyaretçi sayısını öğrenmemizi, ve siteyi ziyaret
            edenlerin kullanım anlarında nasıl hareket ettiklerini anlamamıza
            olanak sağlar. Örneğin, kullanıcıların aradıklarını kolayca
            bulmalarını sağlayacak şekilde sitemizin çalışma yapısını
            geliştirmemize yardımcı olur.
          </li>
          <li>
            İşlevsel çerezler: Sitemize tekrardan girdiğinizde sizi tanımamıza
            olanak sağlar. Böylece içerikler sizin için kişiselleştirebilir,
            sizi isminizle karşılayabilir ve tercihlerinizi hatırlayabiliriz.
            Aşağıdaki tabloda kullandığımız çerezler ve bunların amaçları ile
            ilgili daha fazla bilgi bulabilirsiniz.
          </li>
        </ul>
        <p>Çerezin İsmi Servis Amaç Çerez tipi ve süresi</p>
        <span className="cookies-content-text">
          Open edX sistemin çalışması için gerekli bilgileri içeren çerez
          sisteme giriş yaptığınız süre boyunca tutulur
          <br /> <br />
          Üçüncü taraflara (örneğin video hizmeti sunucuları, web trafik analizi
          hizmetleri vb.) ait çerezler üzerinde kontrolümüz bulunmamaktadır. Bu
          çerezler çoğunlukla analitik/performans amaçlı veya reklam hedefleme
          amaçlı olabilir.
        </span>
        <p>Çerezleri nasıl engellerim veya kaldırırım?</p>
        <span className="cookies-content-text">
          Tarayıcınızda, tüm veya bazı çerezleri reddetmenize izin veren ayarı
          etkinleştirerek çerezleri engelleyebilirsiniz. Ancak, tüm çerezleri
          (temel çerezler dahil) engellemek için tarayıcı ayarlarınızı
          kullanırsanız, sitemizin tamamına veya bir kısmına
          erişemeyebilirsiniz. Tanımlama bilgilerinin ayarlanmasının nasıl
          kaldırılacağı veya engelleneceği hakkında daha fazla bilgi için lütfen
          www.aboutcookies.org adresini ziyaret edin.
        </span>
        <span className="cookies-content-text bottom-text">
          Temel tanımlama bilgileri dışında, tüm tanımlama bilgileri 90 gün
          sonra sona erer.
        </span>
      </div>
    </>
  );
};

export default CookiePolicy;
