import React from 'react';
import { Link } from 'react-router-dom';

import HeroSection from '../../components/HeroSection/HeroSection';

import helpsecone from '../../assets/images/help-one.png';
import helpsecthree from '../../assets/images/help-three.png';
import helpsecfour from '../../assets/images/help-four.png';
import helpsectwo from '../../assets/images/help-two.png';
import heroImage from '../../assets/images/about-us.jpg';

import './aboutUs.sass';

const HelpSectionItem = ({ header, url, image }) => {
  return (
    <Link className="about-us-section-content help-section-content " to={url}>
      <img src={image} alt={header} />
      <span>{header}</span>
    </Link>
  );
};

const AboutUs = () => {
  return (
    <div>
      <HeroSection text="Hakkımızda" src={heroImage} />
      <div className="about-us wrapper">
        <span className="about-us-title title">
          Dayanışma, KTEZO ve ECOSO tarafından mikro ve küçük işletmelerin daha
          rekabet edebilir hale gelmelerini sağlamak hedefiyle kurulan bir iş
          geliştirme desteğidir.
        </span>

        <span className="about-us-content">
          Mikro ve küçük işletmelere yönelik danışmanlık, eğitim ve işbirliği
          fırsatları sunan Dayanışma İş Geliştirme Desteği, Kıbrıs Türk Esnaf ve
          Zanaatkârlar Odası (KTEZO) ile Ekonomik ve Sosyal Politikalar Merkezi
          (ECOSO) işbirliğinde yürütülmekte olan “İş Geliştirme Hizmetleri
          Yoluyla Mikro ve Küçük İşletmelerin Rekabet Edebilirliğini Artırma”
          projesi kapsamında geliştirilmiştir.
          <br /> <br /> Hemen hemen her sektörde faaliyet göstermekte olan mikro
          ve küçük işletmeler ülke ekonomisinin çarklarının dönmesinde rol
          oynayarak, istihdama ve gayri safi yurt içi hasılaya katkı
          koymaktadır. Sosyal ve ekonomik kalkınmanın önemli aktörlerinden olan
          mikro ve küçük işletmeler piyasanın etkinliğini sağlamakta,
          sürdürülebilir gelişimin ve güçlü ekonominin itici gücünü
          oluşturmaktadır.
          <br /> <br /> Tüm KTEZO üyelerine açık olan Dayanışma İş Geliştirme
          Desteği işletmelerin işbirliği ortamında daha rekabet edebilir hale
          gelmelerine yardımcı olmayı ve ani şoklara karşı direngenliklerini
          arttırmaya yönelik akılcı çözümler sunmayı hedeflemektedir.
          <br/><br/>Hizmetlerimiz hakkında daha fazla bilgi edinmek için aşağıdaki
          linkleri kullanabilirsiniz.
        </span>

        <div className="section-wrapper">
          <div className="about-us-section">
            <HelpSectionItem
              image={helpsecone}
              header="Danışmanlık"
              url="/danismanlik"
            />
            <HelpSectionItem image={helpsectwo} header="Eğitim" url="/egitim" />
            <HelpSectionItem
              image={helpsecthree}
              header="Ağ Kurma"
              url="/ag-kurma"
            />
            <HelpSectionItem
              image={helpsecfour}
              header="Finansman"
              url="/finansman"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
