import React from 'react';

import ecosoFooter from '../../assets/images/ecoso-footer.png';
import ktezoLogo from '../../assets/images/ktezo-logo.png';
import flagEurope from '../../assets/images/flag-europe.png';
import instagram from '../../assets/images/instagram.png';
import facebook from '../../assets/images/facebook.png';

import { Link } from 'react-router-dom';

import './footer.sass';

const ContentText = () => {
  return (
    <span className="footer-content-text">
      Bu websitesi Avrupa Birliği’nin finansal desteğiyle Rekabet Edebilir
      İşletmeler, Kümeler ve İş Destek Kurumlarına Yönelik Hibe Programı
      çerçevesinde hazırlanmıştır. İçerik tamamıyla KTEZO ve ECOSO’nun
      sorumluluğu altındadır ve Avrupa Birliği’nin görüşlerini yansıtmak zorunda
      değildir.
    </span>
  );
};
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <img src={flagEurope} className="flag" alt="AB flag" />
        <ContentText />
        <img src={ktezoLogo} className="ktzLogo" alt="ktezo logo" />
        <img src={ecosoFooter} className="ecoso" alt="ecoso" />
      </div>
      <ContentText />
      <div className="footer-component">
        <div>
          <a href="https://www.facebook.com/ktezodayanisma">
            <img src={facebook} alt="facebook" />
            <span>/ktezodayanisma</span>
          </a>
          <a href="https://www.instagram.com/ktezodayanisma/">
            <img src={instagram} alt="instagram" />
            <span>/ktezodayanisma</span>
          </a>
        </div>
        <div>
          <Link to="/gizlilik-politikasi">
            <span className="footer-component-text">Gizlilik Politikası</span>
          </Link>
          <Link to="/cerez-politikasi">
            <span className="footer-component-text">Çerez Politikası</span>
          </Link>
          <Link to="/kullanim-kosullari">
            <span className="footer-component-text">Kullanım Koşulları</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
