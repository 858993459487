import React from 'react';

import './google-form.sass';

const GoogleForm = () => {
  return (
    <div className="google-form-box">
      <div className="google-form">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeV87NXhjdT2R9IVyees3DQjmYE0xTAKIumyNd_PUUKzRVEuQ/viewform?embedded=true&hl=tr"
          frameborder="0"
          style={{
            height: '100%',
            overflow: 'auto',
            width: '750px',
            display: 'block',
            margin: '0 auto'
          }}
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default GoogleForm;
