export const LANGUAGES = {
  ENG: 'en',
  TR: 'tr'
};

export const COURSE_RUN_STATUS = {
  ENROLLED: 'ENROLLED',
  NOT_ENROLLED: 'NOT_ENROLLED',
  NOT_STARTED: 'NOT_STARTED',
  ENDED: 'ENDED',
  INVITE_ONLY: 'INVITE_ONLY'
};

export const SELECT_OBJECT = Object.freeze({
  FIRST: "1",
  SECOND: "2"
});

export const SECTOR_OF_ENTERPRISE = [
  { title: 'TARIM, ORMANCILIK VE BALIKÇILIK' },
  { title: 'MADENCİLİK VE TAŞ OCAKÇILIĞI' },
  { title: 'İMALAT' },
  { title: 'ELEKTRİK, GAZ, BUHAR VE İKLİMLENDİRME ÜRETİMİ VE DAĞITIMI' },
  { title: 'İNŞAAT' },
  {
    title:
      'TOPTAN VE PERAKENDE TİCARET; MOTORLU KARA TAŞITLARININ VE MOTOSİKLETLERİN ONARIMI'
  },
  { title: 'ULAŞTIRMA VE DEPOLAMA' },
  { title: 'KONAKLAMA VE YİYECEK HİZMETİ FAALİYETLERİ' },
  { title: 'BİLGİ VE İLETİŞİM' },
  { title: 'FİNANS VE SİGORTA FAALİYETLERİ' },
  { title: 'GAYRİMENKUL FAALİYETLERİ' },
  { title: 'MESLEKİ, BİLİMSEL VE TEKNİK FAALİYETLER' },
  { title: 'İDARİ VE DESTEK HİZMET FAALİYETLERİ' },
  { title: 'KAMU YÖNETİMİ VE SAVUNMA; ZORUNLU SOSYAL GÜVENLİK' },
  { title: 'EĞİTİM' },
  { title: 'İNSAN SAĞLIĞI VE SOSYAL HİZMET FAALİYETLERİ' },
  { title: 'KÜLTÜR, SANAT, EĞLENCE, DİNLENCE VE SPOR' },
  { title: 'DİĞER HİZMET FAALİYETLERİ' },
  {
    title:
      'HANEHALKLARININ İŞVERENLER OLARAK FAALİYETLERİ; HANEHALKLARI TARAFINDAN KENDİ KULLANIMLARINA YÖNELİK OLARAK AYRIM YAPILMAMIŞ MAL VE HİZMET ÜRETİM FAALİYETLERİ'
  },
  {
    title: 'ULUSLARARASI ÖRGÜTLER VE TEMSİLCİLİKLERİNİN FAALİYETLERİ',
    value: '19'
  }
];

export const REGION_OF_ENTERPRISE = [
  { title: 'Lefkoşa' },
  { title: 'Girne' },
  { title: 'Mağusa' },
  { title: 'İskele' },
  { title: 'Güzelyurt' },
  { title: 'Lefke' }
];
