const sendMail = args => {
  return fetch(process.env.REACT_APP_MAIL_SERVICE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(args)
  });
};

export default sendMail;
