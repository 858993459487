import React from 'react';

import trainings from '../../assets/images/training.jpg';

const ActivitiesItem = ({ text }) => {
  const handleClick = route => {
    window.location.href = `/detay`;
  };

  return (
    <div className="trainings-box" onClick={() => handleClick()}>
      <div className="trainings-box-left">
        <img src={trainings} alt="training"/>
      </div>
      <div className="trainings-box-right">
        <span className="trainings-content-text">{text}</span>
      </div>
    </div>
  );
};

const Activities = () => {
  return (
    <div className="trainings">
      <span className="trainings-title">Faaliyetlerimiz</span>
      <ActivitiesItem text="Mikro ve küçük işletmelerin işletme muhasebesi konusunda hakim olması gereken temel bilgileri öğrenin ve mali tabloları kavrama ve hazırlama becerilerinizi geliştirin." />
      <ActivitiesItem text="Mikro ve küçük işletmelerin işletme muhasebesi konusunda hakim olması gereken temel bilgileri öğrenin ve mali tabloları kavrama ve hazırlama becerilerinizi geliştirin." />
      <ActivitiesItem text="Mikro ve küçük işletmelerin işletme muhasebesi konusunda hakim olması gereken temel bilgileri öğrenin ve mali tabloları kavrama ve hazırlama becerilerinizi geliştirin." />
      <ActivitiesItem text="Mikro ve küçük işletmelerin işletme muhasebesi konusunda hakim olması gereken temel bilgileri öğrenin ve mali tabloları kavrama ve hazırlama becerilerinizi geliştirin." />
      <ActivitiesItem text="Mikro ve küçük işletmelerin işletme muhasebesi konusunda hakim olması gereken temel bilgileri öğrenin ve mali tabloları kavrama ve hazırlama becerilerinizi geliştirin." />
    </div>
  );
};

export default Activities;
