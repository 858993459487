import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import arrow from '../../assets/images/arrow.png';

import './carousel.sass';

const Carousel = ({ data }) => {
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={arrow} alt="arrow-next" />,
    prevArrow: <img src={arrow} alt="arrow-prev" />
  };

  return (
    <div className="carousel-wrapper">
      <Slider {...settings}>
        {data.map(item => (
          <div key={item.id} className="carousel-card">
            <img src={item.image} alt={item.header} className="carousel-background-image"></img>
              <div className="carousel-content">
                <h4 className={`${item.headerHide ? 'hide' : ''}`}>{item.header}</h4>
                <Link to={item.route} className="link-button">Detaylı Bilgi</Link>
              </div>

          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
