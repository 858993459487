import Routes from './routes';
import Providers from './providers';

const App = () => {
  return (
    <div>
      <Providers>
        <Routes />
      </Providers>
    </div>
  );
};

export default App;
