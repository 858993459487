import React from 'react';
import { Link } from 'react-router-dom';

import HelpSection from '../../components/HelpSection/HelpSection';
import HeroSection from '../../components/HeroSection/HeroSection';

import oneStage from '../../assets/images/one-stage.png';
import twoStage from '../../assets/images/two-stage.png';
import threeStage from '../../assets/images/three-stage.png';
import fourStage from '../../assets/images/four-stage.png';
import consultancyImage from '../../assets/images/consultancy.jpg';

import './consultancy.sass';

const Consultancy = () => {
  return (
    <div>
      <HeroSection text="Danışmanlık" src={consultancyImage} />

      <div className="consultancy">
        <span className="consultancy-subtitle">
        İşletmenize özel iş geliştirme danışmanlığı hizmeti
      </span>

        <span className="consultancy-content-text">
          Dayanışma İş Geliştirme Desteği kapsamında Kıbrıs Türk Esnaf ve
          Zanaatkârlar Odası üyesi mikro ve küçük işletmelere:
          <br /> <br />
          <ul>
            <li>Yeni hizmetlerin ve ürünlerin sunumu,</li>
            <li>
              İşletmenin stratejik anlamda etraflı olarak test edilmesi ve
              gelişmesi,
            </li>
            <li>Kârlılığın artırılması,</li>
            <li>Temel hizmet standartlarının iyileştirilmesi</li>
          </ul>
          gibi konularda danışmanlık hizmeti verilmesi amaçlanmaktadır.{' '}
        </span>
        <div className="consultancy-help">
          <span className="help-top-header">
            Danışmanlık Hizmetinin Aşamaları
          </span>
          <div className="help-section">
            <HelpSection
              image={oneStage}
              header="Birinci Aşama"
              to="#"
              text="Uluslararası iyi uygulamalar ışığında mikro ve küçük işletmelere özel tasarlanmış olan İş Tanı Aracı ile işletmenizin temel durumunun röntgeni çekilir."
            />
            <HelpSection
              image={twoStage}
              header="İkinci Aşama"
              to="#"
              text="Tanı aracından çıkacak sonuçlara uygun olarak işletmenizin on iki farklı başlıkta başarılı ve gelişime açık yönleri, ve bu yönlerde gelişimi sağlamak için atılabilecek genel adımlar konusunda bilgi verilir."
            />
            <HelpSection
              image={threeStage}
              to="#"
              header="Üçüncü Aşama"
              text="Özellikle üretim odaklı ve/veya yerel kalkınmayı destekleyici nitelikteki sektörlerdeki üyelerimize, karşı karşıya oldukları sorunları aşmalarına destek olmak amacıyla daha derinlemesine danışmanlık sağlanır."
            />
            <HelpSection
              image={fourStage}
              to="#"
              header="Dördüncü Aşama"
              text="İsteğiniz durumunda gerçekleşecek bir görüşmede ise danışmanınız uygulama süreciniz kapsamında deneyimleriniz, karşılaştığınız zorluklar, ve bir sonraki adımlar konusunda size destek sunar."
            />
          </div>
        </div>
        <span className="consultancy-subtitle">Hemen Randevu Alın!</span>
        <span className="consultancy-content-text">
          Randevu almak için aşağıdaki yollardan birini kullanarak bizimle hemen
          iletişime geçin:
          <ul>
            <li>
              <strong>
                <Link to="/danismanlik-formu">Buraya</Link>{' '}
              </strong>
              tıklayarak online formumuzu doldurun.
            </li>
            <li>
              0548 829 3881 veya 0533 849 10 67 numaralı telefonlarımızdan bize
              ulaşın.
            </li>
            <li>info@ktezodayanisma.org adresimize email yollayın.</li>
          </ul>
        </span>
        <Link to="/iletisim-formu" className="link-button">
          Hemen Randevu Alın
        </Link>
      </div>
    </div>
  );
};

export default Consultancy;
