import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import {useOnClickOutside} from '../../hooks';

import './modal.sass';

const Modal = ({ setModal, title, buttonText, error }) => {
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, setModal);

  return (
    <div className="modal">
      <div className="modal-wrapper">
        <div className={`modal-content ${error && "centered"}`} ref={wrapperRef}>
          <span>{title}</span>
          {!error &&    <Link to="/" className="link-button">
            {buttonText}
          </Link>}
        </div>
      </div>
    </div>
  );
};

export default Modal;
