import React, { useState } from 'react';

import sendMail from '../../api/sendMail';
import { SELECT_OBJECT } from '../../constants';

import TextInput from '../../components/TextInput/TextInput';
import Checkbox from '../../components/Checkbox/Checkbox';
import TextArea from '../../components/TextArea/TextArea';
import Modal from '../../components/Modal/Modal';

import './contactForm.sass';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [isBussinesOwner, setBussinesOwner] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [subject, setSubject] = useState('');

  const [isRegistrationChecked, setIsRegistrationChecked] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [contactInfoError, setContactInfoError] = useState(false);

  const handleClick = async event => {
    event.preventDefault();
    setContactInfoError(false)
    setModalVisibility(true);
    if (subject && email && email === emailConfirmation && name && surname) {
      const msg = {
        to: 'info@ktezodayanisma.org',
        from: 'info@ktezodayanisma.org',
        subject: 'İletişim',
        text: subject,
        html: `<p>${subject}
        <br/> <br/>
         Gönderen: ${name} ${surname}
         <br/>
         Mail: ${email}   <br/>
         ${
           isBussinesOwner === SELECT_OBJECT.FIRST
             ? 'İşletme Sahibi    <br/>'
             : ''
         }
         ${
           isRegistrationChecked === SELECT_OBJECT.FIRST
             ? `Sicil Numarası: ${registrationNumber}`
             : ''
         }
         </p>`
      };

      try {
        await sendMail(msg);
      } catch (err) {
        console.error(err);
      }
    } else {
      setContactInfoError(true);
    }
  };
  return (
    <>
      <form className="contact-form form" onSubmit={handleClick}>
        <span className="contact-form-title form-title">İletişim Formu</span>

        <TextInput title="Adınız" onChange={setName} />

        <TextInput title="Soyadınız" onChange={setSurname} />
        <Checkbox
          checkedElement={isBussinesOwner}
          setCheckedElement={setBussinesOwner}
          header="İşletme sahibi misiniz?"
        />
        <Checkbox
          checkedElement={isRegistrationChecked}
          setCheckedElement={setIsRegistrationChecked}
          header="KTEZO üyesi misiniz?"
        />
        {isRegistrationChecked === SELECT_OBJECT.FIRST && (
          <TextInput
            title="Sicil Numaranız"
            type="number"
            onChange={setRegistrationNumber}
          />
        )}
        <div className="contact-form-box">
          <div className="contact-form-box-left">
            <TextInput title="E-posta" type="email" onChange={setEmail} />
          </div>
          <div className="contact-form-box-right">
            <TextInput
              title="E-posta Tekrar"
              type="email"
              onChange={setEmailConfirmation}
            />
          </div>
        </div>
        <br />

        <TextArea title="Bilgi almak istediğiniz konu" onChange={setSubject} />
        {isModalVisible && (
          <Modal
            setModal={setModalVisibility}
            title={
              contactInfoError
                ? 'Lütfen istenen bilgileri eksiksiz giriniz.'
                : 'İletişim bilgileriniz alınmıştır. Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.'
            }
            buttonText="Ana Sayfaya Dön"
            error={contactInfoError}
          />
        )}
        <button type="submit">Gönder</button>
      </form>
    </>
  );
};

export default ContactForm;
