import React from 'react';
import { Link } from 'react-router-dom';

import HeroSection from '../../components/HeroSection/HeroSection';

import privacyPolicy from '../../assets/images/privacy-policy.jpg';

import './policy-documents.sass';

const PrivacyPolicy = () => {
  return (
    <>
      <HeroSection src={privacyPolicy} text="Gizlilik Politikası" />
      <div className="privacy-policy wrapper">
        <span className="privacy-policy-content-text">
          Gizliliğiniz siz ve bizim için çok önemlidir. Sizinle ilgili bilgileri
          yalnızca bu gizlilik politikasında belirtilen şekilde kullanacağımızı
          taahhüt ederiz. Bu politika (Kullanım Koşulları ve Çerez Politikamız
          ile) sizden topladığımız veya bize sağladığınız kişisel verilerin
          hangi temelde işleneceğini tanımlar.
          <br />
          <br /> Web sitemizi kullanarak, bu Gizlilik Politikasında anlatıldığı
          üzere verilerinizi kullanmamıza izin vermektesiniz.
          <br />
          <br /> Bu gizlilik politikası veya kişisel verilerinizin nasıl
          kullanıldığı konusunda herhangi bir sorunuz, yorumunuz veya talebiniz
          olması durumunda
          <span>info@ktezodayanisma.org</span> adresimize email
          yollayabilirsiniz.
        </span>
        <p>Biz kimiz?</p>
        <span className="privacy-policy-content-text">
          Bu websitesi (
          <Link to="/" className="privacy-policy-content-text">
            www.ktezodayanisma.org
          </Link>
          ,{' '}
          <a
            href="https://lms.ktezodayanisma.org"
            className="privacy-policy-content-text"
          >
            https://lms.ktezodayanisma.org
          </a>
          ), Kıbrıs’ta, KTEZO Sanayi Sitesi, Dr Fazıl Küçük Bulvarı, Hamitköy,
          Lefkoşa adresinde kayıtlı olan Kıbrıs Türk Esnaf ve Zanaatkârlar Odası
          (kayıt no: YK217; bundan sonra ‘KTEZO’ veya ‘Oda’ olarak anılacaktır),
          ve Ali Güçlü Sokak, Metehan, Lefkoşa adresinde kayıtlı olan Centre for
          Economic and Social Policies (kayıt no: M.Ş. 19759, bundan sonra ECOSO
          olarak anılacaktır) tarafından işletilmektedir.
          <br />
          <br />
          89-2007 sayılı Kişisel Verilerin Korunması Yasası ve Genel Veri Koruma
          Tüzüğü (General Data Protection Regulation - GDPR) 2018’in amaçları
          doğrultusunda, veri kontrolörü Kıbrıs’ta, KTEZO Sanayi Sitesi, Dr
          Fazıl Küçük Bulvarı, Hamitköy, Lefkoşa adresinde kayıtlı olan Kıbrıs
          Türk Esnaf ve Zanaatkârlar Odası ve Ali Güçlü Sokak, Metehan, Lefkoşa
          adresinde kayıtlı olan Centre for Economic and Social Policies’dir.
        </span>
        <p className="privacy-policy-subtitle">Ne tür veriler topluyoruz?</p>
        <span className="privacy-policy-content-text">
          Sizin hakkınızda toplayacağımız ve gönüllü olarak bize sağladığınız
          bilgilerin türü, web sitemiz üzerinden bizimle iletişime geçmenize
          veya iş geliştirme danışmanlığı ve/veya eğitimlerimizden yararlanıp
          yararlanmadığınıza bağlı olarak değişecektir.
          <br />
          <br /> İş geliştirme danışmanlığı hizmetlerimizden faydalanmanız
          durumunda, genellikle ek bilgiler talep edeceğiz. Bu kapsamda
          ereceğiniz bilgiler isim, işletme ismi ve sektör bilgisi, email
          adresi, fiziki adres, telefon numarası, sosyal medya kullanıcı
          isimleri, mali bilgiler (işletmenize ilişkin), kişisel tanımlayıcı
          bilgiler, hakkımızda nasıl bilgi sahip olduğunuz ve görüşmek
          istediğiniz konulara ilişkin detayları içerebilir. Bu bilgileri sizden
          gelen talepleri cevaplamak amacıyla, size hizmetimiz veya web sitesi
          hakkında bilgi sunmak, veya size güncel gelişmelerden haberdar etmek
          için kullanmaktayız.
          <br />
          <br />
          Ayrıca hizmetlerimizi kullananların profilini daha iyi anlamak ve
          farklı arka planlardan kişilerin kullanabilmesi yolunda hizmetlerimizi
          geliştirmek amacıyla istatistiki veriler cinsiyet, yaş, uyruk, çalışma
          ve eğitim durumu gibi istatistiki veriler istenebilir. Bu tür
          istatistiki veriler, fonlanan programların gerekliliği olarak fon
          sağlayıcılar ile paylaşılabilir. Buna ek olarak size daha iyi yardımcı
          olabilmek amacıyla, sizinle gerçekleştireceğimiz görüşmelere ve size
          sunduğumuz önerilere, ve bize bu kapsamda vereceğiniz diğer kişisel
          detay, bilgi ve dokümanlara ilişkin notlar tutulabilir.
        </span>
        <p className="privacy-policy-subtitle">
          Verilerinizi nasıl topluyoruz?
        </p>
        <span className="privacy-policy-content-text">
          Bize sağladığınız bilgiler. Bizimle websitesi, telefon, email veya
          video konferans vb. yollardan iletişime geçtiğinizde aşağıdaki
          bilgiler toplanabilmektedir:
          <br /> <br />
          <ul>
            <li>
              Platformumuzdaki eğitimlere erişmek için kayıt olduğunuzda
              verdiğiniz bilgiler,
            </li>
            <li>
              İş geliştirme danışmanlığı için randevu aldığınızda verdiğiniz
              bilgiler,
            </li>
            <li>Etkinliklerimize kayıt olmak için verdiğiniz bilgiler,</li>
            <li>
              Sitemizde herhangi bir form doldurduğunuzda verdiğiniz bilgiler,
            </li>
          </ul>
          <br />
          Sizden topladığımız bilgiler. Sitemize eriştiğinizde, aşağıdaki
          bilgiler otomatik olarak toplanmaktadır: <br />
          <br />
        </span>
        <ul>
          <li>
            teknik bilgiler – cihazınızın internete erişimini sağlamak için
            kullanılan İnternet Protokolü (IP) adresiniz, oturum açma
            bilgileriniz, tarayıcı türü ve sürümü, saat dilimi ayarı, tarayıcı
            eklenti türleri, işletim sistemi ve platform bilgileri dahil.
          </li>
          <li>
            ziyaretinize ilişkin bilgiler – Tekdüzen Kaynak Konum
            Belirleyicileri (Uniform Resource Locators), sitemize gelen, sitemiz
            üzerinden ve sitemizden gelen tıklama akışı (tarih ve saat dahil),
            görüntülediğiniz veya aradığınız hizmetler, sayfa yanıt süreleri,
            indirme hataları, belirli sayfalara ziyaretlerin uzunluğu, sayfa
            etkileşim bilgileri (kaydırma, tıklama ve fareyle üzerine gelme
            gibi, sayfadan uzaklaşmak için kullanılan yöntemler ve
            telefonlarımızı aramak için kullanılan herhangi bir telefon
            numarası.
          </li>
        </ul>
        <p>Verilerinizi Nasıl Kullanacağız?</p>
        <span className="privacy-policy-content-text">
          Verilerinizi aşağıdakiler ışığında işlenir:
          <br /> <br /> 1. Üye işletmelerimize, onların yararına ve bir bütün
          olarak yerel kalkınmaya katkı sağlamak için ücretsiz destek
          sağlamadaki meşru menfaatlerimiz için <br /> 2. Sözleşmeli bir program
          kapsamında hizmet sağladığımızda sözleşmenin performansı amacıyla (ör:
          programın fon sağlayıcılar tarafından fonlandırılması durumunda){' '}
          <br /> 3. Sizin için; üyelerimizin KTEZO’nun Dayanışma programı
          kapsamında sunduğu ücretsiz destekten yararlanma menfaati <br /> 4.
          İlk kez bireysel tavsiye verdiğimizde talep ettiğimiz özel rızanız
          için.
        </span>
        <p>Kişisel Verilerinizi Nasıl Saklarız?</p>
        <span className="privacy-policy-content-text">
          Bilgilerinizi yalnızca yasalarca gerekli olduğu sürece veya toplanma
          amaçlarıyla ilgili olduğu sürece saklanır. Bilgilerinize yetkisiz veya
          yasadışı erişimi veya kazara kayıp veya imha veya hasarı önlemek için
          uygun teknik ve güvenlik önlemleri alınmıştır. Özellikle kişisel
          bilgileriniz güvenli bir sunucuda saklanacaktır.
          <br /> <br /> Ne yazık ki, internet üzerinden bilgi aktarımı tamamen
          güvenli değildir. Kişisel verilerinizi korumak için elimizden geleni
          yapacağız, fakat sitemize aktarılan verilerinizin güvenliğini garanti
          edemeyiz; herhangi bir iletim riski size aittir. Bilgilerinizi
          aldıktan sonra, yetkisiz erişimi önlemeye çalışmak için katı
          prosedürler ve güvenlik özellikleri kullanacağız.
          <br /> <br /> Sitemizin belli kısımlarına erişim sağlaması için size
          şifre verilmesi (veya sizin belirlemeniz) durumunda, bu şifrenin gizli
          tutulmasından siz sorumlusunuz. Bu şifrenin başka biri ile
          paylaşılmamasını rica ederiz. <br /> <br /> Uygun yasal bir gereklilik
          olmadıkça, verileriniz yukarıda ayrıntıları verilen tarafların dışında
          saklanmayacak, işlenmeyecek veya aktarılmayacaktır. Hukuken bunu
          yapmaktan menedilmedikçe, bizimle iletişime geçerek ve
          ayrıntılarınızın işlenmesinin kısıtlanmasını veya silinmesini talep
          ederek istediğiniz zaman onayınızı geri alma hakkına sahipsiniz. Bazen
          verilerinizi sözleşmeden doğan sorumluluklarımız nedeniyle saklamamız
          gerekebilir (ör: KTEZO ile katıldığınız bir program var ise). Daha
          fazla bilgi için <span>info@ktezodayanisma.org </span> ile iletişime
          geçebilirsiniz.
          <br /> <br /> Aksi takdirde, basılı kopya verilerinin imhası da dahil
          olmak üzere verileriniz mümkün olan en kısa sürede silinir.
        </span>
        <p>Bilgilerinizin Paylaşımı</p>
        <span className="privacy-policy-content-text">
          Aşağıdaki durumlarda kişisel verilerinizi paylaşma hakkına sahip
          olduğumuzu onaylamaktasınız:
        </span>
        <ul>
          <li>
            Google Analytics (aşağıda belirtildiği gibi), Google Fonts, Adobe
            Typekit, YouTube, Facebook ve Instagram gibi harici izleme
            hizmetleri de dahil olmak üzere sitemizin iyileştirilmesi ve
            optimizasyonunda bize yardımcı olan arama motoru sağlayıcıları;
          </li>
          <li>
            Web sitesinin ve hizmetlerin tamamıyla üçüncü bir tarafa
            aktarılması; böyle bir durumda kullanıcı bilgileri aktarılan
            varlıklar arasında olacaktır.
          </li>
          <li>
            Herhangi bir yasal zorunluluğa uymak, kullanım koşullarımızı
            uygulamak, veya diğer anlaşmaları uygulamak için kişisel
            verilerinizi paylaşma yükümlülüğümüz varsa; veya KTEZO’nun,
            üyelerinin veya başkalarının haklarını, mülkiyetini veya güvenliğini
            korumak için. Bu, dolandırıcılıktan korunma ve amacıyla diğer ilgili
            kurumlarla bilgi alışverişini içerir.
          </li>
        </ul>
        <p>Veri Koruma Haklarınız Nedir?</p>
        <span className="privacy-policy-content-text">
          Kişisel verilerinizi pazarlama amacıyla işlemememizi bizden isteme
          hakkına sahipsiniz. Verilerinizi toplamak için kullandığımız
          formlardaki belirli kutuları işaretleyerek engelleme hakkınızı
          kullanabilirsiniz. Ayrıca istediğiniz zaman abonelikten çıkma ve/veya
          fikrinizi değiştirme hakkını kullanabilir ve bizimle{' '}
          <span>info@ktezodayanisma.org </span> adresinden iletişime geçerek
          bildirimde bulunabilirsiniz.
          <br />
          <br /> Sitemiz, zaman zaman işbirliğimiz olan kurumların web
          sitelerine, üyelerimize faydalı bilgiler sunacağını düşündüğümüz web
          sitelerine bağlantılar içerebilir. Bu web sitelerinden herhangi birine
          giden bir bağlantıyı izlerseniz, lütfen bu web sitelerinin kendi
          gizlilik politikaları olduğunu ve bu politikalar için herhangi bir
          sorumluluk veya yükümlülük kabul etmediğimizi unutmayın. Bu web
          sitelerine herhangi bir kişisel veri göndermeden önce lütfen bu
          politikaları kontrol edin.
        </span>
        <p> Gizlilik Politikamıza Değişiklikler</p>
        <span className="privacy-policy-content-text">
          Gizlilik politikamıza yapacağımız herhangi bir değişiklik bu sayfada
          yayınlanacaktır. Bu gizlilik politikası ilk olarak Mayıs 2021’de
          yayınlanmıştır.
        </span>
      </div>
    </>
  );
};

export default PrivacyPolicy;
