import React from 'react';

import HeroSection from '../../components/HeroSection/HeroSection';

import financing from '../../assets/images/financing.jpg';

import './financing.sass';

const Financing = () => {
  const handleClick = route => {
    window.location.href = `/${route}`;
  };

  const download = link => {
    window.open(link);
  };

  return (
    <div>
      <HeroSection text="Finansman" src={financing} />

      <div className="financing">
        <span className="financing-subtitle">
          Mikro ve Küçük İşletmelere Yönelik Hibe Programı
        </span>
        <div className="top-box">
          <div className="top-box-left">
            <span className="financing-content-text table">
              Son Başvuru Tarihi{' '}
            </span>
            <span className="financing-content-text table">
              Soru Sorma İçin Son Tarih
            </span>
            <span className="financing-content-text table">
              Soru ve Cevapların Yayınlanma Tarihi
            </span>
          </div>
          <div className="top-box-right">
            <span className="financing-content-text">
              : <b>25 Ekim 2021</b>
            </span>
            <span className="financing-content-text">
              : <b>11 Ekim 2021</b>
            </span>
            <span className="financing-content-text">
              : <b>17 Ekim 2021</b>
            </span>
          </div>
        </div>
        <div>
          <span className="financing-content-text">
            KTEZO üyelerine yönelik, minimum 10,000 Euro ve maksimum 20,000
            Euro’luk geri ödemesiz hibe programımızın uygulama süreci
            tamamlanmıştır. Farklı finansman olanakları için duyurularımızı
            websitemizden veya sosyal medya kanallarımızdan takip edebilirsiniz.
          </span>
          <br />
          <br />
          <strong className="financing-second-title financing-sub-header">
            Ne tür projelerle başvuru yapılabilir?
          </strong>
          <span className="financing-content-text">
            Program kapsamında, aşağıdaki öncelikli alanlardan birine hizmet
            eden başarılı projeler sunan işletmeler hibe almaya hak
            kazanacaktır;
          </span>
          <ul>
            <li>
              Yeni ve/veya niş ve/veya özgün hizmet ve/veya ürün geliştirme
            </li>
            <li>
              Satış ve/veya üretim miktarını artırma ve/veya hizmet sunumunu
              geliştirme
            </li>
            <li>İşletmenin karlılığını artırma</li>
            <li>
              Gıda güvenliği ve/veya hijyen standartlarını ve/veya hizmet
              kalitesi standartlarını artırma
            </li>
          </ul>
          <strong className="financing-second-title financing-sub-header">
            Kimler Başvurabilir?
          </strong>
          <ul className="financing-content-text">
            <li>Başvuru tarihinde en az 1 yıldır kurulu olan, ve</li>
            <li>Kuruluş tarihi 31 Aralık 2015’ten sonra olan, ve</li>
            <li>Kıbrıs’ın kuzey kesiminde kurulu olan, ve</li>
            <li>
              AB’nin “Küçük ve Orta Boy İşletme” tanımına uyan tüm KTEZO üyesi
              işletmelere açıktır.
            </li>
          </ul>
          <span className="financing-content-text">
            Başvuru yapacak işletmelerin, son başvuru tarihinden önce Dayanışma
            Eğitim Portalında bulunan eğitimlerin en az 5’ini tamamlaması
            gerekmektedir.
          </span>
          <br /> <br />
          <strong className="financing-second-title financing-sub-header">
            Hibe programı hakkında detaylı bilgiye nasıl ulaşabilirim?
          </strong>
          <br />
          <span className="financing-content-text">
            Hibe programı kuralları ve başvuru kriterleri hakkında daha fazla
            bilgi almak için aşağıdaki linkteki dokümanları inceleyebilirsiniz.
            <br />
            <a
              className="financing-content-text"
              onClick={() =>
                download(
                  'https://trclldx.fra1.cdn.digitaloceanspaces.com/ecoso/01%20DayanismaHibe%20Guidelines_TR_Corrigendum1.pdf'
                )
              }
              target="_blank"
            >
              <b>Dayanışma Hibe Programı - Hibe Rehberi – Değişiklik No.1</b>
            </a>
            <br />
            <a
              className="financing-content-text"
              onClick={() =>
                download(
                  'https://trclldx.fra1.digitaloceanspaces.com/ecoso/02 EK 1 - DayanismaHibe Basvuru Formu_TR.docx'
                )
              }
            >
              <b>Başvuru Formu</b>
            </a>{' '}
            ve{' '}
            <a
              className="financing-content-text"
              onClick={() =>
                download(
                  'https://trclldx.fra1.digitaloceanspaces.com/ecoso/03 EK 2 - DayanismaHibe Beyanlar.docx'
                )
              }
            >
              <b>Başvuru Sahibi Beyanı</b>
            </a>
            <br />
            <a
              className="financing-content-text"
              onClick={() =>
                download(
                  'https://trclldx.fra1.cdn.digitaloceanspaces.com/ecoso/Dayanisma_Hibe%20Sorular%20ve%20Cevaplar%20No1.pdf'
                )
              }
              target="_blank"
            >
              <b>Dayanışma Hibe Programı - Sorular ve Cevaplar No1</b>
            </a>
            <br />
            <a
              className="financing-content-text"
              onClick={() =>
                download(
                  'https://trclldx.fra1.cdn.digitaloceanspaces.com/ecoso/Dayanisma_Hibe%20Sorular%20ve%20Cevaplar%20No2.pdf'
                )
              }
              target="_blank"
            >
              <b>Dayanışma Hibe Programı - Sorular ve Cevaplar No2</b>
            </a>
            <br />
            <br />
            <br /> <br />
          </span>
          <strong className="financing-second-title financing-sub-header">
            Hibe programı konusunda kiminle iletişime geçebilirim?
          </strong>
          <br />
          <span className="financing-content-text">
            Hibe programı hakkında sorularınız var ise, bizimle
            info@ktezodayanisma.org adresinden yukarıda belirtilen son soru
            sorma tarihinin öncesinde iletişime geçebilirsiniz. Program hakkında
            genel bilgi almak için İletişim Formumuzu kullanabilirsiniz.
          </span>
        </div>
        <div className="support-section">
          <span className="financing-subtitle">
            Esnaf ve Zanaatkârlara Yönelik Diğer Finansal Destekler
          </span>

          <strong className="financing-second-title financing-sub-header">
            İşletmelere Can Suyu
          </strong>
          <span className="financing-content-text">
            Avrupa Birliği tarafından finanse edilen ve COVID-19 salgınının
            Kıbrıs Türk toplumunda yarattığı daha geniş kapsamlı sosyo-ekonomik
            etkilerin giderilmesine katkı sağlamayı amaçlayan ekonomik destek
            paketinin bir parçası olarak şahıs, mikro ve küçük işletmelere
            yönelik destek programı ''İşletmelere Can Suyu Desteği'' açılmıştır.
            Toplam bütçesi 3 milyon Euro olan destek programı kapsamında
            COVID-19 salgınının yarattığı etki sebebiyle yaşanan olası nakit
            akışı sorunlarının aşılmasına yardımcı olmak için uygun işletmelere
            18.000 TL'ye kadar finansal destek sağlanmıştır.
            <br />
            <br /> Destek programı, Avrupa Birliği'nin finanse ettiği ve
            Northern Ireland Cooperation Overseas (NI-CO) tarafından yürütülen
            ''İnovatif Girişimcilik ve Diyalog'' Projesi kapsamında, Kıbrıs Türk
            Yatırım Geliştirme Ajansı (YAGA), Kıbrıs Türk Esnaf ve Zanaatkârlar
            Odası (KTEZO), Kıbrıs Türk Ticaret Odası (KTTO) ve Kıbrıs Türk
            Restorancılar Birliği (RES-BİR) ile iş birliği halinde
            yönetilmiştir. Yiyecek ve içecek hizmetleri sektörüne destek
            sağlamak amacıyla uygulanan birinci aşama 25 Eylül 2020 tarihinde
            son bulmuş ve şu ana kadar yaklaşık 800 işletmeye ödemeler
            aktarılmıştır. Programın 2. Aşamasında ise saç ve güzellik
            hizmetleri sektöründeki uygun işletmelere destek sağlanmış ve 600’ün
            üzerinde işletme destekten yararlanmıştır.
          </span>
          <strong className="financing-second-title financing-sub-header">
            Düşük Faizli Esnaf Kredileri
          </strong>
          <span className="financing-content-text">
            Anlaşmalı bankalar ve KTEZO Yardımlaşma ve Teminat Kooperatifi’nin
            üyelere sağladığı düşük faizli kredi imkanları yıl boyunca
            sunulmaktadır.
            <br />
            <br /> Esnaf kredileri hakkında daha ayrıntılı bilgi almak için
            aşağıdaki numaralardan bizimle irtibata geçin: 0548 829 38 81
          </span>
          <br />
          <span className="financing-content-text">
            Üyelerimizin faydalanabileceği diğer finansal destek mekanizmaları
            hakkında bilgi almak için bize ulaşın.
          </span>
        </div>

        <div className="footer-button">
          <button onClick={() => handleClick('iletisim-formu')}>
            İletişim
          </button>
        </div>
      </div>
    </div>
  );
};

export default Financing;
