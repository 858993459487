import React from 'react';

import './textInput.sass';

const TextInput = ({ onChange, title, placeholder, type, pattern }) => {
  
  const onChanged = event => {

    if (onChange && event.target.validity.valid) {
      onChange(event.target.value)};
  };

  return (
    <div className="text-input">
      <span>{title}</span>
      <input
        className={type}
        type={type}
        onChange={event => onChanged(event)}
        placeholder={placeholder}
        pattern={pattern}
      />
    </div>
  );
};

export default TextInput;
